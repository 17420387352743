html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: rgb(0, 0, 0,1);
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

canvas {
  display: block;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#leva__root {
  opacity: 0.7;
}

:root {
  --leva-colors-highlight2: #fff !important
}